
import { Component, Provide, Vue, Watch } from "vue-property-decorator";
import { marketplaceRouteMeta } from "@/types/localtypes";
import { Route } from "vue-router";
import { MPSelfHostedRequest } from "@jfrog-ba/myjfrog-common";
import { LoadingMaskProps } from "@/types/loadingMask";
import LoadingMask from "@/components/common/LoadingMask.vue";
import SvgInline from "@/components/common/SvgInline.vue";
import logger from "@/util/logger";

@Component({
  name: "MPLandingGCP",
  components: { LoadingMask, SvgInline },
})
export default class MPLanding extends Vue {
  @Provide() eventBus = new Vue();
  routeParentChildMeta: marketplaceRouteMeta = {};
  request!: MPSelfHostedRequest;
  currentRouteName = "";
  canSubmitForm = false;
  isLoading = false;
  isSessionSet = false;

  created() {
    this.getRouteParentChildMeta();
    this.createRequest();
  }

  get isLoginDone() {
    return this.isSessionSet;
  }
  async createRequest() {
    let token = this.$route.query.token as string;
    this.request = {
      mpToken: token,
      mpOrigin: "GCP_MP",
    };
    sessionStorage.setItem("request", JSON.stringify(this.request));
    logger.warn("request is set");
  }

  @Watch("$route")
  onRouteChange(to: Route, from: Route) {
    this.getRouteParentChildMeta();
  }

  get slogan() {
    switch (this.routeParentChildMeta.subscriptionType) {
      case "BOM_BASED":
        return { headline: "Self-hosted", description: " via GCP Marketplace" };
    }
  }

  get routeParentChildMetaSteps() {
    return this.routeParentChildMeta.steps;
  }

  getRouteParentChildMeta() {
    this.$route.matched.forEach(m => {
      for (let key in m.meta) {
        this.routeParentChildMeta[key] = m.meta[key];
      }
    });

    const step = Number(this.routeParentChildMeta.step);
    this.currentRouteName = this.routeParentChildMetaSteps[step - 1];
  }

  get loadingMaskProps(): LoadingMaskProps {
    return {
      loading: this.isLoading,
    };
  }

  onCanSubmitForm(value: boolean) {
    this.canSubmitForm = value;
  }

  setRequest(data: MPSelfHostedRequest) {
    this.request = data;
  }
}
